<template>
  <div class="info-dis-con">
    <NavBar
      :title="title"
      left-arrow
      fixed
      z-index="9999"
      @click-left="$router.go(-1)"
      placeholder
    />
    <div class="info-dis-con-inner">
      <!-- <router-link class="return-back" to="/info_dis_nav">返回 ></router-link> -->
      <img
        src="./img/xinxipiloushengming-4.png"
        alt=""
        class="content-bg"
        v-if="needId === '0'"
      />
      <img
        src="./img/jigoubeian-0.png"
        alt=""
        class="content-bg"
        v-else-if="needId === '1'"
      />
      <img
        src="./img/jigouzuzhi-1.png"
        alt=""
        class="content-bg"
        v-else-if="needId === '2'"
      />
      <!--<img src="./img/jigoushenhexinxi-5.png" alt="" class="content-bg" v-else-if="needId === '3'">-->
      <div class="needId3" v-else-if="needId === '3'">
        <img src="./img/jigoushenhexinxi-5.png" class="content-bg" />
        <div class="needId3-btn">出具中，待发布</div>
      </div>
      <div class="page3" v-else-if="needId === '4'">
        <img src="./img/jigoujingying-2-1.png" alt="" class="content-bg" />
        <div class="data">
          <table>
            <tr v-for="(item, index) in infoDis" :key="index">
              <td class="cell1">{{ item.name }}</td>
              <td class="cell2">{{ item.value }}</td>
            </tr>
          </table>
          <div class="text">
            （数据截止至{{ deadline }}，来源于理财范内部统计）
          </div>
        </div>
        <img src="./img/jigoujingying-2-2.png" alt="" class="content-bg" />
      </div>
      <img
        src="./img/zhongdashixiang-3.png"
        alt=""
        class="content-bg"
        v-else-if="needId === '5'"
      />
      <img
        src="./img/qitaxinxi-8.png"
        alt=""
        class="content-bg"
        v-else-if="needId === '6'"
      />
      <div class="pla-ope-report" v-else-if="needId === '7'">
        <div class="title">平台运营报告</div>
        <ul class="list">
          <li class="option">
            <router-link to="/half_2018" class="link link2">
              2018年半年报
            </router-link>
            <router-link to="/full_2017" class="link link1">
              2017年年报
            </router-link>
          </li>
        </ul>
      </div>
      <div v-else-if="needId === '8'" class="wlzspj">
        <!-- 网络借贷知识普及 -->
        <div class="text">
          <a href="http://www.gov.cn/xinwen/2015-07/18/content_2899360.htm"
            >• 关于促进互联网金融健康发展的指导意见</a
          ><br />
          <a
            href="http://www.cbrc.gov.cn/govView_37D312933F1A4CECBC18F9A96293F450.html"
            >• 网络借贷信息中介机构业务活动管理暂行办法</a
          ><br />
          <a href="http://www.court.gov.cn/fabu-xiangqing-15146.html"
            >• 最高人民法院关于审理民间借贷案件适用法律若干问题的规定</a
          ><br />
          <a
            href="http://www.cbrc.gov.cn/govView_C8D68D4C980A4410B9F4E21BA593B4F2.html"
            >• 中国银监会《网络借贷信息中介机构信息披露指引》</a
          >
        </div>
        <div class="title-level1">▪︎ 名词解释</div>
        <div class="title-level2">• 网络借贷</div>
        <div class="text">
          什么是网络借贷:网络借贷是指个体和个体之间通过互联网平台实现的直接借贷.个体包含自然人,法人及其他组织。网络借贷信息中介机构是指依法设立，专门从事网络借贷信息中介业务活动的金融信息中介公司。该类机构以互联网为主要渠道，为借款人与出借人(即贷款人)实现直接借贷提供信息搜集、信息公布、资信评估、信息交互、借贷撮合等服务。
        </div>
        <div class="text">
          网络借贷信息中介机构按照依法、诚信、自愿、公平的原则为借款人和出借人提供信息服务，维护出借人与借款人合法权益，不得提供增信服务，不得直接或间接归集资金，不得非法集资，不得损害国家利益和社会公共利益。
          借款人与出借人遵循借贷自愿、诚实守信、责任自负、风险自担的原则承担借贷风险。网络借贷信息中介机构承担客观、真实、全面、及时进行信息披露的责任，不承担借贷违约风险。
        </div>
        <div class="title-level1">▪︎ 风险控制与协议</div>
        <div class="text">
          风险控制（风控）：追求稳健发展，保持必要谨慎，风控持续创新。理财范在风控上成立了专业的风险控制管理委员会，建立完善的风控制度，在贷前、贷中、贷后都有风控专业人员把控；
          同时在动态管理、主动防御金融风险的理念下，数据源和数据库持续更新迭代，不断强化大数据风险识别能力。我们认为即使是仅作为提供金融信息中介服务的P2P网贷平台，在互联网金融领域，其根本还是金融，获取优质的资产是我们的长期话题。
        </div>
        <div class="text">
          贷前审核：在原则性尽职调查基础上对借款人的行业情况、道德风险、资金用途合规及合法性、抵质押物情况等多个纬度实施审慎考量。
        </div>
        <div class="text">
          贷后管理：平台及第三方合作机构对借款人及抵质押物情况进行定期追踪检查。若发现借款人未按规定用途使用借款等造成借款回收风险加大的情形，将在合法授权下采取相关保全措施。若借款人出现逾期、坏账，平台将依照合同约定配合迅速启动资产处置、催收程序，保证出借人利益。
        </div>
        <div class="text">
          专款专项：按照监管要求进行银行存管合作，对出借人交易资金完全按照“专户专款专用”的标准模式进行存管。该存管账户的提现划转均由存管银行按程序规定。<br />
          用户账户及信息保护：平台全站采用国际标准的128位SSL加密传输，确保无法破解防止被攻击，同时配备市场最高级别的商业防攻击防火墙，并严格按照隐私条款对用户信息进行保密。
        </div>
        <div class="text">
          用户账户及信息保护：平台全站采用国际标准的128位SSL加密传输，确保无法破解防止被攻击，同时配备市场最高级别的商业防攻击防火墙，并严格按照隐私条款对用户信息进行保密。
        </div>
        <div class="text">
          电子合同：根据《合同法》和《电子签名法》的规定，当事人可以采用合同书、信件和数据电文（包括电报、电传、传真、电子数据交换和电子邮件）等形式订立合同，并通过以电子形式所含、所附用于识别签名人身份并表明签名人认可其中内容的数据电文等电子签名方式进行签署，当事人不能仅因合同采用电子签名、数据电文的形式就否定其法律效力。
          因此，作为平台的注册用户要对平台采用的电子签名、数据电文的形式予以认可。
        </div>
        <div class="title-level1">▪︎ 管理办法（暂行）</div>
        <div class="title-level2">• 备案管理</div>
        <div class="text">
          开展网络借贷信息中介服务的网络借贷信息中介机构及其分支机构，应当在领取营业执照后，于10个工作日以内携带有关材料向工商登记注册地地方金融监管部门备案登记。
          <br />
          地方金融监管部门负责为网络借贷信息中介机构办理备案登记。地方金融监管部门应当在网络借贷信息中介机构提交的备案登记材料齐备时予以受理，并在各省(区、市)规定的时限内完成备案登记手续。备案登记不构成对网络借贷信息中介机构经营能力、合规程度、资信状况的认可和评价。<br />
          网络借贷信息中介机构完成地方金融监管部门备案登记后，应当按照通信主管部门的相关规定申请相应的电信业务经营许可;未按规定申请电信业务经营许可的，不得开展网络借贷信息中介业务。
        </div>
        <div class="title-level2">• 信息披露</div>
        <div class="text">
          网络借贷信息中介机构应当在其官方网站上向出借人充分披露借款人基本信息、融资项目基本信息、风险评估及可能产生的风险结果、已撮合未到期融资项目资金运用情况等有关信息。披露内容应符合法律法规关于国家秘密、商业秘密、个人隐私的有关规定。<br />
          网络借贷信息中介机构应当引入律师事务所、信息系统安全评价等第三方机构，对网络信息中介机构合规和信息系统稳健情况进行评估。<br />
          网络借贷信息中介机构应当将定期信息披露公告文稿和相关备查文件报送工商登记注册地地方金融监管部门，并置备于机构住所供社会公众查阅。
        </div>
        <div class="title-level2">• 资金存管</div>
        <div class="text">
          网络借贷信息中介机构应当实行自身资金与出借人和借款人资金的隔离管理，并选择符合条件的银行业金融机构作为出借人与借款人的资金存管机构。借款人、出借人、网络借贷信息中介机构、资金存管机构、担保人等应当签订资金存管协议，明确各自权利义务和违约责任。<br />
          资金存管机构对出借人与借款人开立和使用资金账户进行管理和监督，并根据合同约定，对出借人与借款人的资金进行存管、划付、核算和监督。<br />
          资金存管机构承担实名开户和履行合同约定及借贷交易指令表面一致性的形式审核责任，但不承担融资项目及借贷交易信息真实性的实质审核责任。<br />
          资金存管机构应当按照网络借贷有关监管规定报送数据信息并依法接受相关监督管理。
        </div>
        <div class="title-level1">▪︎ 网贷风险防范</div>
        <div class="text">
          3月5日十二届全国人大五次会议正式召开，在政府工作报告中“互联网金融”再次被提及，从2014年以来政府工作报告已连续四年提及互联网金融。互联网金融领域P2P网贷的兴起及备受关注，既是它在我国现代金融市场体系中发挥了其相应的效能，又是伴之而来出现的风险。那么作为网贷出借人，应该如何正确认识、规避出借风险呢？请各位出借人谨记：市场有风险，出借需谨慎。
        </div>
        <div class="text">一、选择具有成熟风险控制体系的平台</div>
        <div class="text">
          合规性保障<br />
          P2P平台作为金融信息服务中介平台，其提供的服务应是居间撮合服务；平台产品及业务活动应是符合监管规定的。<br />
          信用风险控制<br />
          平台应建立自有的风险控制团队，坚持风控审核，形成贷前、贷中、贷后联动优化的风控闭环，才能真正有利于平台良性发展、降低产品信用风险。<br />
          技术安全保障<br />
          平台互联网技术力量是P2P平台安全和出借人资金安全的重要保障，也是P2P平台风险控制体系内的重要一环。根据监管规定，P2P平台在技术安全保障上需要做到：应按照国家网络安全相关规定和国家信息安全等级保护制度的要求，开展信息系统定级备案和等级测试；
          需配备完善的防火墙、入侵检测、数据加密以及灾难恢复等网络安全设施和管理制度；建立信息科技管理、科技风险管理和科技审计有关制度；第四，记录并留存借贷双方上网日志信息，信息交互内容等数据；应当建立或使用与其业务规模相匹配的应用级灾备系统设施。<br />
        </div>
        <div class="text">二、选择标的真实的项目。</div>
        <div class="text">
          平台安全真实的标的是基础，出借人需谨防存在“庞氏骗局”性质的平台。而平台应按照政策、法律等规定的披露准则为出借人展现尽可能较为完善的资料。
        </div>
        <div class="text">三、合理的利率收益。</div>
        <div class="text">
          从目前的市场环境来看，P2P平台处于降息态势，出借人不可贪恋高收益，而不考虑风险，趋于平稳的利率是平台安全的标志之一。
        </div>
        <div class="text">四、银行存管。</div>
        <div class="text">
          为防止平台私自挪用借贷资金，P2P网贷平台应积极拥抱监管，与银行进行存管业务对接，在银行设立一个专门的资金存管账户，与平台自身的自有资金账户分离开。为平台出借人提供更为安全可靠的资金存管服务。
        </div>
        <div class="bottom">市场有风险，出借需谨慎。</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api'
import { NavBar } from 'vant'

export default {
  props: ['id', 'title'],
  name: 'InfoDisCon',
  components: {
    NavBar,
  },
  data() {
    return {
      needId: '',
      infoDis: [],
      deadline: '',
      infoDisNum: 0,
    }
  },
  mounted() {
    this.needId = this.$route.params.id
    // 请求
    this.requestData()
  },
  methods: {
    redirectPDF() {
      this.$router.push({
        path: '/pdf_preview?targetUrl=https://dn-licaifan-static.qbox.me/images.pdf',
      })
    },
    // 接口 信息披露需要的数据
    requestData() {
      api.general
        .informationDisclosure()
        .then((response) => {
          const res = response.data
          if (res.code === -1) {
            this.infoDis = res.data.web_data
            this.infoDisNum = res.data.web_data.length
            this.deadline = res.data.deadline
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((error) => {
          this.$message.error(error.toString())
        })
    },
  },
}
</script>
<style scoped>
.info-dis-con {
  background: #fff;
  width: 100%;
  font-size: 16px;
}
.info-dis-con-inner {
  padding-top: 32px;
  width: 90%;
  margin: 0 auto;
}
.info-dis-con .content-bg {
  width: 100%;
  position: relative;
  z-index: 10;
}
.info-dis-con .return-back {
  position: fixed;
  z-index: 20;
  top: 30px;
  right: 5%;
  font-size: 16px;
  color: #999;
  cursor: pointer;
}
.info-dis-con .wlzspj {
  padding-top: 16px;
}
.info-dis-con .wlzspj .title-level1 {
  font-size: 20px;
  line-height: 38px;
  text-align: left;
  color: #171717;
  margin-bottom: 8px;
}
.info-dis-con .wlzspj .title-level2 {
  font-size: 18px;
  line-height: 32px;
  text-align: left;
  color: #666;
}
.info-dis-con .wlzspj .text,
.info-dis-con .wlzspj .text a {
  line-height: 30px;
  font-size: 16px;
  color: #999;
  margin-bottom: 8px;
}
.info-dis-con .wlzspj .text a {
  display: inline-block;
  margin-bottom: 8px;
}
.info-dis-con .wlzspj .bottom {
  line-height: 30px;
  font-size: 16px;
  color: #999;
  margin-bottom: 8px;
  text-align: center;
  padding: 32px 0 32px 0;
}
/* 数据区 */
.info-dis-con .page3 .data {
  margin: 0 0 8px 0;
  color: #5a606a;
}
.info-dis-con .page3 .data table {
  border-top: 1px solid #5a606a;
  border-left: 1px solid #5a606a;
  border-collapse: collapse;
  width: 100%;
}
.info-dis-con .page3 .data td {
  border-right: 1px solid #5a606a;
  border-bottom: 1px solid #5a606a;
}
.info-dis-con .page3 .data .cell1 {
  width: 55%;
  height: 32px;
  padding-left: 4%;
}
.info-dis-con .page3 .data .cell2 {
  text-align: right;
  padding-right: 4%;
}
.info-dis-con .page3 .data .text {
  line-height: 32px;
  padding: 16px 0 0 0;
  color: #5a606a;
}
/* 平台运营报告 */
.pla-ope-report .title {
  font-size: 18px;
  line-height: 32px;
  text-align: left;
  color: #666;
}
.pla-ope-report .list {
  padding-top: 16px;
}
.pla-ope-report .option {
  width: 90%;
  height: 32px;
  line-height: 32px;
  margin-bottom: 16px;
}
.pla-ope-report .option .link {
  display: block;
  font-size: 18px;
  width: 100%;
  height: 100%;
}
.needId3 {
  position: relative;
  padding-bottom: 30px;
  /*background: url("./img/jigoushenhexinxi-5.png") top center no-repeat ;*/
}
.needId3-btn {
  position: absolute;
  bottom: 45px;
  left: 5%;
  /*height: 25px;*/
  text-align: center;
  /*line-height: 25px;*/
  z-index: 100;
  /*background: #fff;*/
  font-size: 10px;
  /*color: #004ff1;*/
  color: #999;
}
.pla-ope-report .option .link {
  display: inline-block;
  margin-bottom: 16px;
}
.pla-ope-report .option .link1 {
  /*background: url('./img/2017full.jpg') no-repeat center;*/
}
.pla-ope-report .option .link2 {
  /*background: url('./img/2017half.png') no-repeat center;*/
}
</style>
